@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    a {
        @apply text-blue-500 hover:underline;
    }

}